<template>
	<router-view class="router"/>
</template>

<script>
	import caches from '@cache/cache.js';
	export default {
		components: {},
		data() {
			return {};
		},
		// created() {
		// 	let menu = caches.topMenu.get()
		// 	menu.forEach(item=>{
		// 		console.log(item,item.url,this.$route.name)
		// 		if(item.url===this.$route.name){
		// 			this.$router.replace({
		// 				name: item.child[0].child[0].url
		// 			});
		// 			return
		// 		}
		// 	})
		// },
		methods: {},
	};
</script>

<style lang='scss' scoped>
	.router{
		padding:12px;
		overflow: hidden !important;
	}
	.content {
		margin: 12px;
	}

	.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}

	.outerMenu.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}
</style>
